import axios from "axios";
import * as ActionType from "./admin.type";
import { setToast } from "../../../util/toast";
import { apiInstanceFetch } from "../../../util/api";



export const loginAdmin = (login) => (dispatch) => {
  axios
    .post("admin/admin/login", login)
    .then((res) => {
      if (res.data.status) {
        dispatch({ type: ActionType.LOGIN_ADMIN, payload: res.data.token });
        setToast("success", "Login Successfully!");
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.response.data.message);
    });
};

export const getProfile = () => (dispatch) => {
  apiInstanceFetch
    .get("admin/admin/profile")
    .then((res) => {
      if (res.status) {
        dispatch({ type: ActionType.UPDATE_PROFILE, payload: res.user });
      } else {
        setToast("error", res.message);
      }
    })
    .catch((error) => {
      console.log("error", error.message);
    });
};

export const changePassword = (data) => (dispatch) => {
  axios
    .patch(`admin/admin/updatePassword`, data)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Password Changed Successfully.");
        setTimeout(() => {
          dispatch({ type: ActionType.UNSET_ADMIN });
          window.location.href = "/";
        }, [1000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => setToast("error", error.message));
};
export const profileUpdate = (formData) => (dispatch) => {
  axios
    .patch("admin/admin/updateProfile", formData)
    .then((res) => {
      if (res.data.status) {
        dispatch({
          type: ActionType.UPDATE_PROFILE,
          payload: res.data.admin,
        });
        setToast("success", "Admin update Successfully");
      }
    })
    .catch((error) => {
      setToast("error", error);
    });
};

export const sendEmail = (login) => (dispatch) => {
  axios
    .post("admin/admin/forgotPassword", login)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Email Send For Forget The Password! ");
        setTimeout(() => {
          window.location.href = "/";
        }, [2000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.response.data.message);
    });
};

export const setPasswordApi = (login) => (dispatch) => {
  axios
    .post(`admin/admin/setPassword`, login)
    .then((res) => {
      if (res.data.status) {
        setToast("success", "Password Changed Successfully.");
        setTimeout(() => {
          window.location.href = "/";
        }, [2000]);
      } else {
        setToast("error", res.data.message);
      }
    })
    .catch((error) => {
      setToast("error", error.response.data.message);
    });
};

export const getAdminEarnings =
  (startDate, endDate, start, limit) => (dispatch) => {
    apiInstanceFetch
      .get(
        `admin/premiumPlan/getpremiumPlanHistory?startDate=${startDate}&endDate=${endDate}&start=${start}&limit=${limit}`
      )
      .then((res) => {
        if (res.status) {
          dispatch({
            type: ActionType.ADMIN_EARNING,
            payload: {
              earning: res.history,
              total: res.totalHistory,
              totalEarning: res.totalAdminEarnings,
            },
          });
        }
      });
  };
